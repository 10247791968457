import { CommandButton, TextField } from "@fluentui/react";
import React, { useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../components/button";
import Layout from "../components/layout";
import { LinkStandard } from "../components/link";
import SEO from "../components/seo";
import { useTheme } from "../components/ThemeProvider";
import { Text } from "../components/typography";

function Contact(props) {
  const theme = useTheme();
  return (
    <Layout {...props}>
      <SEO
        title="Contact"
        keywords={[`blog`, `gatsby`, `javascript`, `react`, `contact`, `email`]}
      />
      <Text variant="large_C">Reach out, why don't you?</Text>
      <form
        name="contact"
        data-netlify="true"
        netlify-honeypot="bot-field"
        method="POST"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <TextField
          type="email"
          name="email"
          required
          placeholder="your email address"
          styles={{ root: { marginTop: 10, marginBottom: 10 } }}
        ></TextField>
        <TextField
          type="message"
          name="message"
          required
          placeholder="your message"
          multiline
          styles={{ root: { marginTop: 0, marginBottom: 10 } }}
        ></TextField>
        <ButtonPrimary type="submit" value="Submit">
          Submit
        </ButtonPrimary>
      </form>
    </Layout>
  );
}

export default Contact;
